import React from 'react';
import {
  Text,
  Field,
  Placeholder,
  ComponentRendering,
  ComponentParams,
  ComponentFactory,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid } from '@material-ui/core/';

import './index.scss';

type FooterProps = {
  fields: {
    copyright: Field<string>;
  };
  componentFactory: ComponentFactory;
  rendering: ComponentRendering;
  params: ComponentParams;
};

const SimpleFooter = (props: FooterProps): JSX.Element => {
  return (
    <footer>
      <div className="simple-footer-wrapper">
        <div className="footer-fullwidth-wrapper">
          <div className="footer-legal-container">
            <Grid container>
              <Grid item xs={10}>
                <Grid container className="footer-legal-spacing">
                  <Grid item xs={12} className="footer-legal-grid">
                    <Placeholder name="footer_legal" rendering={props.rendering} />
                  </Grid>
                  <Grid item xs={12} className="footer-copyright-container">
                    <span>
                      © {new Date().getFullYear()} <Text field={props.fields.copyright} />
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SimpleFooter;
