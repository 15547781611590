import React from 'react';

import {
  Text,
  RichText,
  Image,
  Field,
  LinkFieldValue,
  ImageField,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import Button from '@material-ui/core/Button';

import './index.scss';

type CalloutProps = {
  fields: {
    Heading: Field<string>;
    Description: Field<string>;
    Image: Field<ImageField>;
    Link: Field<LinkFieldValue>;
  };
  params: {
    BackgroundStyle: Field<string>;
    ImagePosition: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const Callout = ({ fields, params }: CalloutProps): JSX.Element => {
  const backgroundType = JSON.stringify(params?.BackgroundStyle);
  let alternateStyle = false;
  let primaryStyle = false;
  let listingStyle = false;
  let isImageUsed = false;
  // Temporary fix for Insert Media Items in General Link not resolving to correct link
  let friendlyURL = fields.Link.value.href;
  friendlyURL = friendlyURL?.replace('sitecore/media-library', '-/media');

  if (backgroundType === '"primary-design"') {
    primaryStyle = true;
  } else if (backgroundType === '"alternate-design"') {
    alternateStyle = true;
  } else if (backgroundType === '"listing-design"') {
    listingStyle = true;
  }

  if (!(Object.keys(fields.Image.value).length === 0)) {
    isImageUsed = true;
  }

  return (
    <>
      {primaryStyle ? <div className="green-body-icons"></div> : ''}
      <div
        className={
          `callout${primaryStyle ? ' primary-background full-width' : ''}${
            alternateStyle ? ' alternate-background' : ''
          }${listingStyle ? ' listing-design' : ''} ` + params?.ImagePosition
        }
      >
        <div className="callout-background">
          <div className={'callout-wrapper ' + params?.ImagePosition}>
            {isImageUsed ? (
              <div className="image-column">
                {fields.Link?.value?.href ? (
                  <a href={fields.Link?.value?.href} target={fields.Link?.value?.target}>
                    <Image className="image img-responsive" field={fields.Image?.value} />
                  </a>
                ) : (
                  <Image className="image img-responsive" field={fields.Image?.value} />
                )}
              </div>
            ) : (
              ''
            )}
            <div className="text-column">
              <div>
                <Text tag="span" className="subheading" field={fields.Heading} />
              </div>
              <RichText className="description" field={fields.Description} />
              <div>
                {fields.Link?.value?.href ? (
                  <a href={friendlyURL} target={fields.Link?.value?.target}>
                    <Button
                      color={primaryStyle ? 'primary' : 'secondary'}
                      variant="contained"
                      className={primaryStyle ? 'primary-contrast' : ''}
                    >
                      {fields.Link?.value?.text}
                    </Button>
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ConfigCheck = ({ fields, params, sitecoreContext }: CalloutProps): JSX.Element => {
  if (fields && sitecoreContext.pageEditing) {
    return (
      <>
        <div>
          <span>
            Callout Component Edit Mode (Background Style: {params?.BackgroundStyle} Image Position:{' '}
            {params?.ImagePosition})
          </span>
          <Callout fields={fields} params={params} sitecoreContext={sitecoreContext} />
        </div>
      </>
    );
  } else if (fields) {
    return <Callout fields={fields} params={params} sitecoreContext={sitecoreContext} />;
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(ConfigCheck);
