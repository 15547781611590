import React from 'react';
import {
  Text,
  SitecoreContext,
  LinkFieldValue,
  withSitecoreContext,
  ImageField,
  Field,
  RichText,
} from '@sitecore-jss/sitecore-jss-react';

import { ResponsiveImage, ResponsiveImageProps } from '../ResponsiveImage';
import Button from '@material-ui/core/Button';
import './index.scss';

// Params added to match new SearchField props requirement
type BorderCalloutProps = {
  fields: {
    Heading: Field<string>;
    Description: Field<string>;
    'Desktop Image': ImageField;
    'Mobile Image': ImageField;
    'Tablet Image': ImageField;
    Link: Field<LinkFieldValue>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const BorderCallout = ({ fields, sitecoreContext }: BorderCalloutProps): JSX.Element => {
  if (fields) {
    let friendlyURL = fields?.Link?.value?.href;
    friendlyURL = friendlyURL?.replace('sitecore/media-library', '-/media');
    let imageObj: ResponsiveImageProps = {
      responsiveFields: {
        MobileImageSrc: fields['Mobile Image'],
        TabletImageSrc: fields['Tablet Image'],
        DesktopImageSrc: fields['Desktop Image'],
      },
      sitecoreContext: sitecoreContext,
    };
    return (
      <div className="border-callout-container">
        {sitecoreContext.pageEditing && (
          <>
            <p>Border Callout Component Edit Mode</p>
          </>
        )}
        {
          <>
            <div className="text-column">
              <div>
                <Text tag="h1" className="title" field={fields?.Heading} />
              </div>
              <RichText className="additional-text" field={fields?.Description} />
              <div>
                {fields?.Link?.value?.href ? (
                  <a href={friendlyURL} target={fields?.Link?.value?.target}>
                    <Button className="callout-button">{fields?.Link?.value?.text}</Button>
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="image-container">
              <ResponsiveImage
                responsiveFields={imageObj.responsiveFields}
                sitecoreContext={imageObj.sitecoreContext}
              />
            </div>
          </>
        }
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(BorderCallout);
