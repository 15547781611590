import React from 'react';
import {
  Text,
  Field,
  RichText,
  LinkFieldValue,
  SitecoreContext,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid, Button } from '@material-ui/core';
import Helmet from 'react-helmet';
import './index.scss';

interface Location {
  value: Field<string>;
  fields: {
    'Location Name': Field<string>;
    'Street Address 1': Field<string>;
    'Street Address 2': Field<string>;
    'Street Address 3': Field<string>;
    City: Field<string>;
    'Postal Code': Field<string>;
  };
}

type JobPostingDetailsContentProps = {
  fields: {
    'Position Category': Field<string>;
    'Job Title': Field<string>;
    'Position Location': Location;
    'Job ID': Field<string>;
    Type: Field<string>;
    'Hours or Shifts': Field<string>;
    Department: Field<string>;
    'Reports to': Field<string>;
    Description: Field<string>;
    Responsibilities: Field<string>;
    'Qualifications and Experience': Field<string>;
    'Posting Date': Field<string>;
    'Closing Date': Field<string>;
    'Application Link': Field<LinkFieldValue>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean };
};

const JobPostingDetailsContent = ({
  sitecoreContext,
  fields,
}: JobPostingDetailsContentProps): JSX.Element => {
  if (fields) {
    var date = new Date(fields?.['Closing Date']?.value);
    let pathname = typeof window !== 'undefined' ? window.location.href : '';
    let postingDate = fields?.['Posting Date']?.value;
    if (postingDate === '0001-01-01T00:00:00Z') {
      postingDate = '';
    }
    let validThrough = fields?.['Closing Date']?.value;
    if (validThrough === '0001-01-01T00:00:00Z') {
      validThrough = '';
    }
    var formatter = Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    });
    var dateString = formatter
      .formatToParts(date)
      .map(({ type, value }) => {
        switch (type) {
          case 'day':
            return `${value} `;
          case 'month':
            return `${value},`;
          case 'year':
            return `${value}`;
          default:
            return value;
        }
      })
      .join('');

    const Job_Schema = JSON.stringify({
      '@context': 'http://schema.org/',
      '@type': 'JobPosting',
      title: `${fields['Job Title']?.value}`,
      description: `${fields?.Description?.value}`,
      identifier: {
        '@type': 'PropertyValue',
        name: 'UPMC',
        value: `${fields['Job ID']?.value}`,
      },
      datePosted: `${postingDate}`,
      validThrough: `${validThrough}`,
      employmentType: `${fields?.Type?.value}`,
      hiringOrganization: {
        '@type': 'MedicalOrganization',
        name: 'UPMC in Croatia',
        url: `${pathname}`,
        logo: 'https://cdn.upmc.com/-/media/upmc/logos/upmclogosvg.svg',
      },
      jobLocation: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          streetAddress: `${fields['Position Location']?.fields?.['Location Name']?.value}`,
          addressLocality: `${fields['Position Location']?.fields?.['Street Address 2']?.value}`,
          addressRegion: `${fields['Position Location']?.fields?.City?.value}`,
          addressCountry: 'IE',
        },
      },
      applicantLocationRequirements: {
        '@type': 'Country',
        name: 'Croatia',
      },
      directApply: 'FALSE',
    });

    return (
      <div className="job-posting-details">
        {sitecoreContext.pageEditing && (
          <>
            <p>Job Posting Details Content Component Edit Mode</p>
          </>
        )}
        <Grid container item alignItems="flex-start">
          <Grid item xs={12}>
            <Text
              tag="span"
              className="job-posting-details-category"
              field={fields['Position Category']}
            />
            <Text tag="h1" className="display-name" field={fields['Job Title']} />

            <div className="job-posting-details-banner-wrapper">
              <div className="job-posting-details-banner-details">
                <div className="job-posting-details-banner-details-innner">
                  <ul>
                    {fields?.['Position Location']?.fields?.['Location Name']?.value ? (
                      <li>
                        <strong>Location:</strong>{' '}
                        {fields['Position Location']?.fields?.['Location Name']?.value}
                      </li>
                    ) : null}
                    {fields['Job ID']?.value ? (
                      <li>
                        <strong>Job ID:</strong> {fields['Job ID']?.value}
                      </li>
                    ) : null}
                    {fields.Type?.value ? (
                      <li>
                        <strong>Type:</strong> {fields.Type?.value}
                      </li>
                    ) : null}
                    {fields['Hours or Shifts']?.value ? (
                      <li>
                        <strong>Hours/Shift:</strong> {fields['Hours or Shifts']?.value}
                      </li>
                    ) : null}
                    {fields.Department?.value ? (
                      <li>
                        <strong>Department:</strong> {fields.Department?.value}
                      </li>
                    ) : null}
                    {fields['Reports to']?.value ? (
                      <li>
                        <strong>Reports to:</strong> {fields['Reports to']?.value}
                      </li>
                    ) : null}
                    {postingDate ? (
                      <li>
                        <strong>Job Post Close Date:</strong> {dateString}
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        {fields['Application Link']?.value?.href ? (
          <a
            className="job-posting-application-link"
            href={
              fields['Application Link']?.value?.href +
                fields['Application Link']?.value?.querystring || ''
            }
            target={fields['Application Link']?.value?.target}
          >
            <Button color="secondary" variant="contained">
              {fields['Application Link']?.value?.text}
            </Button>
          </a>
        ) : null}
        <div>
          <div className="job-posting-details-biography">
            <div className="job-posting-details-biography-wrapper">
              {fields?.Description?.value ? (
                <Grid
                  container
                  item
                  alignItems="flex-start"
                  justifyContent="space-between"
                  className="details-section"
                >
                  <Grid item xs={12} md={4}>
                    <h2>Description:</h2>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <RichText field={fields.Description} />
                  </Grid>
                </Grid>
              ) : null}
              {fields?.Responsibilities?.value ? (
                <Grid
                  container
                  item
                  alignItems="flex-start"
                  justifyContent="space-between"
                  className="details-section"
                >
                  <Grid item xs={12} md={4}>
                    <h2>Responsibilities:</h2>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <RichText field={fields.Responsibilities} />
                  </Grid>
                </Grid>
              ) : null}
              {fields?.['Qualifications and Experience']?.value ? (
                <Grid
                  container
                  item
                  alignItems="flex-start"
                  justifyContent="space-between"
                  className="details-section"
                >
                  <Grid item xs={12} md={4}>
                    <h2>Qualifications and Experience:</h2>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <RichText field={fields['Qualifications and Experience']} />
                  </Grid>
                </Grid>
              ) : null}
            </div>
          </div>
        </div>

        <Helmet>
          <script className="structured-data-list" type="application/ld+json">
            {Job_Schema}
          </script>
        </Helmet>
      </div>
    );
  } else if (!fields && sitecoreContext.pageEditing) {
    return <p>Please setup the Datasource.</p>;
  }
  return <></>;
};

export default withSitecoreContext()(JobPostingDetailsContent);
