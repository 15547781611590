import React from 'react';
import { ResultList as HeadlessResultList, SearchEngine } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { GetRelativeUrl } from '../utils';
import { useTranslation } from 'react-i18next';
interface ResultListProps {
  controller?: HeadlessResultList;
  hostName?: string;
  engine: SearchEngine;
  result?: any;
}

export const PhysicianResult: FunctionComponent<ResultListProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller?.state);
  const { t, i18n } = useTranslation();

  useEffect(() => controller?.subscribe(() => setState(controller?.state)), [controller]);

  function getSpecialties(props: any) {
    var Specialties = props.result.raw.physicianspecialties;
    return (
      <>
        {Specialties?.map((specialty: any, i: any) => (
          <>
            <span className="speciality" key={i}>
              {specialty + ' '}
            </span>
          </>
        ))}
      </>
    );
  }

  function getSubspecialties(props: any) {
    var Subspecialties = props.result.raw.physiciansubspecialties;
    return (
      <>
        {Subspecialties?.map((subspecialty: any, i: any) => (
          <>
            {i === 0 && (
              <span className="subspecialty" key={i}>
                {subspecialty}
              </span>
            )}
            {i > 0 && (
              <span className="subspecialty" key={i}>
                {' - '} {subspecialty}
              </span>
            )}
          </>
        ))}
      </>
    );
  }

  function getLocations(props: any) {
    var locations = props.result.raw.physicianlocations;
    return (
      <>
        {locations?.map((location: any, i: any) => (
          <>
            {location}
            <br />
          </>
        ))}
      </>
    );
  }

  function getPhoneList(props: any) {
    var numbers = props.result.raw.physicianphonenumbers;
    return (
      <>
        {numbers?.map((num: any, i: any) => (
          <li key={i}>
            {t('physician-telephone')}: <a href={'tel:' + num}>{num}</a>
          </li>
        ))}
      </>
    );
  }

  function getUrl(props: any) {
    return GetRelativeUrl(props.result);
  }

  return (
    <>
      {/* Flex container for photo and details container */}
      <Grid container className="consultant-profile-wrapper">
        <Grid item xs={12} sm={3}>
          <div className="image-container">
            <a href={getUrl(props)}>
              <img
                src={
                  props.result.raw.physicianimageurl
                    ? props.result.raw.physicianimageurl
                    : '/-/media/project/upmc/croatia/images/physicians/stu/upmc-croatia-physician.png'
                }
                alt={props.result.raw.physicianimagealt}
              />
            </a>
          </div>
        </Grid>
        {/* Container for specialty, location, and contact */}
        <Grid item xs={12} sm={9} className="details-wrapper">
          {/* Title & Full Name */}
          <h2 className="display-name">
            <a href={getUrl(props)}>{props.result.raw.displayz32xname}</a>
          </h2>
          {/* Specialty */}
          <h3 className="specialty-heading">{getSpecialties(props)}</h3>
          <span className="subspecialty-heading">{getSubspecialties(props)}</span>
          <Grid container className="contact-location-container">
            {props?.result?.raw?.physicianphonenumbers ? (
              <Grid item xs={12} md={6}>
                {/* Contacts */}
                <h6 className="contact-heading">{t('physician-referral-contact')}</h6>
                <ul className="phone-list">{getPhoneList(props)}</ul>
              </Grid>
            ) : null}
            {props?.result?.raw?.physicianlocations ? (
              <Grid item xs={12} md={6}>
                {/* Location */}
                <h6 className="locations-heading">{t('physician-locations')}</h6>
                <span className="consultant-location">{getLocations(props)}</span>
              </Grid>
            ) : null}
          </Grid>
          <div className="view-profile">
            <a href={getUrl(props)}>
              <Button color="secondary" variant="contained">
                {t('physician-view-profile')}
              </Button>
            </a>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
