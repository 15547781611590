import React from 'react';
import Helmet from 'react-helmet';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import { ThemeProvider } from '@material-ui/styles';
import './assets/app.scss';
import CroatiaTheme from './Theme';
import SkipToContent from './components/SkipToContent/index.tsx';
import PageProperties from './components/PageProperties/index.tsx';
import GeneralContext from './context/general';
import HeadlessEngine from './components/Coveo/lib/engine.tsx';
import config from './temp/config';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route }) => {
  const generalContext = {
    generalSearchEngine: new HeadlessEngine(config.coveoQueryPipeline, 'CroatiaSearchHub').engine,
  };

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>{route?.fields['Page Browser Title']?.value || route?.name}</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Helmet>

      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      <VisitorIdentification />

      {/* root placeholder for the app, which we add components to using route data */}
      <>
        <GeneralContext.Provider value={generalContext}>
          <ThemeProvider theme={CroatiaTheme}>
            <SkipToContent />
            <PageProperties />
            <Placeholder name="page_header" rendering={route} />
            <main id="main-content">
              <Placeholder name="marketing_alert" rendering={route} />
              <Placeholder name="page_breadcrumbs" rendering={route} />
              <Placeholder name="page_sublayout" rendering={route} />
            </main>
            <Placeholder name="page_footer" rendering={route} />
          </ThemeProvider>
        </GeneralContext.Provider>
      </>
    </React.Fragment>
  );
};

export default Layout;
