import React from 'react';
import {
  Text,
  Field,
  RichText,
  LinkFieldValue,
  Link,
  Image,
  ImageField,
  SitecoreContext,
  withSitecoreContext,
  Placeholder,
  RouteData,
  ComponentRendering,
} from '@sitecore-jss/sitecore-jss-react';
import { Grid, Container } from '@material-ui/core';
import Helmet from 'react-helmet';
import { Schedule, ScheduleProps } from './physician-schedule';
import { List } from 'reselect/es/types';
import { SpecialtyTitle } from './physician-specialty';
import { SubspecialtyTitle } from './physician-subspecialty';
import { useTranslation } from 'react-i18next';
import './index.scss';

type PhysicianDetailsContentProps = {
  rendering: RouteData | ComponentRendering;
  sitecoreContext: SitecoreContext & {
    pageEditing: boolean;
    route: {
      fields: {
        'Physician Image': Field<ImageField>;
        Title: Field<string>;
        'Display Name': Field<string>;
        'First Name': Field<string>;
        'Last Name': Field<string>;
        'Secretary Heading': Field<string>;
        'Secretary Name': Field<string>;
        'Telephone Heading': Field<string>;
        'Phone Number 1': Field<string>;
        'Phone Number 2': Field<string>;
        'Phone Number 3': Field<string>;
        'Phone Number 4': Field<string>;
        Email: Field<string>;
        'Email Heading': Field<string>;
        'Book Visit Link': Field<LinkFieldValue>;
        'Book Video Physician Link': Field<LinkFieldValue>;
        'Schedules Heading': Field<string>;
        Schedules: [ScheduleProps];
        'Accepts Insurances Heading': Field<string>;
        'Accepts Insurances': Field<string>;
        Specialty: Field<string>;
        'Sub Specialty Heading': Field<string>;
        Subspecialties: Field<List>;
        Specialties: Field<List>;
        'Qualifications Heading': Field<string>;
        'Qualifications and Training': Field<string>;
        'Professional Biography Heading': Field<string>;
        'Professional Biography': Field<string>;
        'CV Resume Heading': Field<string>;
        CV: Field<LinkFieldValue>;
        Resume: Field<LinkFieldValue>;
      };
    };
  };
};

const PhysicianDetailsContent = ({
  sitecoreContext,
  rendering,
}: PhysicianDetailsContentProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  let pathname = typeof window !== 'undefined' ? window.location.href : '';
  let fields = sitecoreContext?.route?.fields;
  let image = fields['Physician Image'];
  let displayName = sitecoreContext?.route?.fields['Display Name'];
  let title = fields?.Title;
  let fname = fields['First Name'];
  let lname = fields['Last Name'];
  let fallbackDisplayName = title.value + ' ' + fname.value + ' ' + lname.value;
  let secretaryHeading = t('specialist-secretary-heading');
  let secretaryName = sitecoreContext?.route?.fields['Secretary Name'];
  let telephoneHeading = t('specialist-telephone-heading');
  let phoneNum1 = sitecoreContext?.route?.fields['Phone Number 1'];
  let phoneNum2 = sitecoreContext?.route?.fields['Phone Number 2'];
  let phoneNum3 = sitecoreContext?.route?.fields['Phone Number 3'];
  let phoneNum4 = sitecoreContext?.route?.fields['Phone Number 4'];
  let email = sitecoreContext?.route?.fields.Email;
  let emailHeading = t('specialist-email-heading');
  let bookVisitLink = sitecoreContext?.route?.fields['Book Visit Link'];
  let bookVideoPhysicianLink = sitecoreContext?.route?.fields['Book Video Physician Link'];
  let schedulesHeading = t('specialist-schedules-heading');
  let acceptsInsuranceHeading = t('specialist-accepts-insurances-heading');
  let acceptsInsurance = sitecoreContext?.route?.fields['Accepts Insurances'];
  let subSpecialHeading = t('specialist-subspecialty-heading');
  let qualificationsHeading = t('specialist-qualifications-heading');
  let qualificationsAndTraining = sitecoreContext?.route?.fields['Qualifications and Training'];
  let professionalBiographyHeading = t('specialist-professional-biography-heading');
  let professionalBiography = sitecoreContext?.route?.fields['Professional Biography'];
  let cvResumeHeading = t('specialist-cv-resume-heading');
  let specialties = sitecoreContext?.route?.fields?.Specialties;
  let subSpecialties = sitecoreContext?.route?.fields?.Subspecialties;
  let sSchedule = sitecoreContext?.route?.fields?.Schedules;
  let scheduleList = [];
  // Temporary fix for Insert Media Items in General Link not resolving to correct link
  let cv = fields?.CV;
  cv.value.href = cv.value.href?.replace('sitecore/media-library', '-/media');
  let resume = fields?.Resume;
  resume.value.href = resume.value.href?.replace('sitecore/media-library', '-/media');

  for (let i = 0; i < sSchedule.length; i++) {
    if (sSchedule[i].fields.Location !== null) {
      scheduleList.push(sSchedule[i]);
    }
  }

  const Person_Schema = JSON.stringify({
    '@context': 'http://schema.org/',
    '@type': 'Person',
    name: `${displayName}`,
    image: `${image}`,
    url: `${pathname}`,
    knowsAbout: `${specialties}`,
    hasCredential: {
      '@type': 'EducationalOccupationalCredential',
      credentialCategory: `${qualificationsAndTraining}`,
    },
  });

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Placeholder name="page_top_section" rendering={rendering} />
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="stretch">
        <Grid item xs={12} className="one-column-sublayout">
          {fields ? (
            <div className="physician-details">
              {sitecoreContext.pageEditing && (
                <>
                  <p>PhysicianDetails Component Edit Mode</p>
                </>
              )}
              <>
                <Grid container item alignItems="flex-start">
                  <Grid item xs={12}>
                    {displayName?.value ? (
                      <Text tag="h1" className="display-name" field={displayName} />
                    ) : (
                      <h1 className="display-name">{fallbackDisplayName}</h1>
                    )}
                    <SpecialtyTitle fields={fields}></SpecialtyTitle>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={3}>
                    <div className="physician-banner-wrapper">
                      <div className="physician-banner-img">
                        <Image className="image img-responsive" field={image?.value} />
                      </div>
                      <div className="physician-banner-details">
                        <div className="physician-banner-details-innner">
                          <ul>
                            {secretaryName?.value ? (
                              <>
                                <li className="physician-contact-subheading">{secretaryHeading}</li>
                                <Text tag="li" field={secretaryName} />
                              </>
                            ) : (
                              ''
                            )}
                          </ul>
                          <ul>
                            {phoneNum1?.value ||
                            phoneNum2?.value ||
                            phoneNum3?.value ||
                            phoneNum4?.value ? (
                              <>
                                <li className="physician-contact-subheading">{telephoneHeading}</li>
                                {phoneNum1?.value ? (
                                  <li>
                                    <a className="physician-phone" href={'tel:' + phoneNum1.value}>
                                      {phoneNum1.value}
                                    </a>
                                  </li>
                                ) : (
                                  ''
                                )}

                                {phoneNum2?.value ? (
                                  <li>
                                    <a className="physician-phone" href={'tel:' + phoneNum2.value}>
                                      {phoneNum2.value}
                                    </a>
                                  </li>
                                ) : (
                                  ''
                                )}
                                {phoneNum3?.value ? (
                                  <li>
                                    <a className="physician-phone" href={'tel:' + phoneNum3.value}>
                                      {phoneNum3.value}
                                    </a>
                                  </li>
                                ) : (
                                  ''
                                )}
                                {phoneNum4?.value ? (
                                  <li>
                                    <a className="physician-phone" href={'tel:' + phoneNum4.value}>
                                      {phoneNum4.value}
                                    </a>
                                  </li>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </ul>
                          <ul>
                            {email?.value ? (
                              <a className="physician-email" href={'mailto:' + email.value}>
                                {emailHeading}
                              </a>
                            ) : (
                              ''
                            )}
                          </ul>
                          {bookVisitLink?.value?.href && bookVisitLink?.value?.url ? (
                            <div className="physician-book-visit">
                              <a
                                className="cta"
                                href={bookVisitLink?.value?.href}
                                target={bookVisitLink?.value?.target}
                              >
                                {bookVisitLink?.value?.text}
                              </a>
                            </div>
                          ) : (
                            ''
                          )}
                          {bookVideoPhysicianLink?.value?.href &&
                          bookVideoPhysicianLink?.value?.url ? (
                            <div className="physician-book-visit">
                              <a
                                className="cta secondary"
                                href={bookVideoPhysicianLink?.value?.href}
                                target={bookVideoPhysicianLink?.value?.target}
                              >
                                {bookVideoPhysicianLink?.value?.text}
                              </a>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8} lg={9}>
                    <div>
                      <div className="physician-biography">
                        <div className="physician-biography-wrapper">
                          {scheduleList.length > 0 ? (
                            <ul className="physician-locations-list">
                              {fields?.Schedules?.length > 0 ? (
                                <li className="physician-schedule-subheading">
                                  {schedulesHeading}
                                </li>
                              ) : null}
                              <div className="physician-schedule">
                                {fields?.Schedules?.length > 0 ? (
                                  fields.Schedules.map((schedule: ScheduleProps, i: number) => (
                                    <Schedule
                                      key={i}
                                      Day={schedule?.fields?.Day}
                                      Location={schedule?.fields?.Location}
                                    />
                                  ))
                                ) : sitecoreContext.pageEditing ? (
                                  <p className="Content-Author-Alert">
                                    Please configure a schedule for the physician.
                                  </p>
                                ) : null}
                              </div>
                            </ul>
                          ) : null}
                          {cv?.value?.href || resume?.value?.href ? (
                            <ul className="physician-resume-list">
                              <li className="physician-resume-subheading">{cvResumeHeading}</li>
                              {cv?.value?.href ? (
                                <li>
                                  <img
                                    className="physician-icon"
                                    alt="cv-icon"
                                    src="/-/media/Project/UPMC/Croatia/Icons/cv.svg"
                                  />
                                  <Link className="physician-resume-details" field={cv} />
                                </li>
                              ) : sitecoreContext.pageEditing ? (
                                <p className="Content-Author-Alert">
                                  Please configure a CV for the physician.
                                </p>
                              ) : null}
                              {resume?.value?.href ? (
                                <li>
                                  <img
                                    className="physician-icon"
                                    alt="pdf-icon"
                                    src="/-/media/Project/UPMC/Croatia/Icons/pdf.svg"
                                  />
                                  <Link className="physician-resume-details" field={resume} />
                                </li>
                              ) : sitecoreContext.pageEditing ? (
                                <p className="Content-Author-Alert">
                                  Please configure a Resume for the physician.
                                </p>
                              ) : null}
                            </ul>
                          ) : null}
                          {typeof subSpecialties !== 'undefined' &&
                          Object.values(subSpecialties)?.length > 0 ? (
                            <ul className="physician-subSpecialty-list">
                              <li className="physician-subSpecialty-subheading">
                                {subSpecialHeading}
                              </li>
                              <div className="physician-specialty-details">
                                <SubspecialtyTitle fields={fields}></SubspecialtyTitle>
                              </div>
                            </ul>
                          ) : null}
                          {qualificationsAndTraining?.value ? (
                            <>
                              <span className="physician-qulifications-subheading">
                                {qualificationsHeading}
                              </span>
                              <Text
                                tag="p"
                                className="physician-qualifications-details"
                                field={qualificationsAndTraining}
                              />
                            </>
                          ) : null}
                          {acceptsInsurance?.value ? (
                            <>
                              <span className="physician-insurance-subheading">
                                {acceptsInsuranceHeading}
                              </span>
                              <Text
                                tag="p"
                                className="physician-insurance-details"
                                field={acceptsInsurance}
                              />
                            </>
                          ) : null}
                          {professionalBiography?.value ? (
                            <>
                              <span className="physician-biography-heading">
                                {professionalBiographyHeading}
                              </span>
                              <RichText
                                field={professionalBiography}
                                className="physician-biography-details"
                              />
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <Helmet>
                  <script className="structured-data-list" type="application/ld+json">
                    {Person_Schema}
                  </script>
                </Helmet>
              </>
            </div>
          ) : null}
          <Placeholder name="page_content" rendering={rendering} />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Placeholder name="page_section" rendering={rendering} />
        </Grid>
      </Grid>
    </Container>
  );
};
export default withSitecoreContext()(PhysicianDetailsContent);
